// i18next-extract-mark-ns-start products-wave-cs1

import { LINKS, WAVE_APPLICATIONS_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductWavePage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<h2>Cold Ambient Warm-up Performance Prediction</h2>
						<h3>
							Using accurate and integrated 1D modelling methods to
							achieve results
						</h3>
						<p>
							Using <Link to={LINKS.PRODUCTS_WAVE}>WAVE</Link> and{' '}
							<a
								href="https://www.mentor.com/"
								target="_blank"
								rel="noreferrer">
								Flowmaster,
							</a>{' '}
							<a
								href="https://www.cseg.us/"
								target="_blank"
								rel="noreferrer">
								Computational Sciences Experts Group
							</a>{' '}
							(CSEG) modelled a 2L gasoline turbocharged direct
							injection engine for the benefit of predicting and
							quantifying cold ambient heat rejection.
						</p>
						<p>
							<strong>Challenge</strong>
						</p>
						<p>
							A vehicle’s fast and efficient warm-up has a significant
							impact on occupant comfort, vehicle performance and
							tail-pipe emissions. Prior to a vehicle being built,
							thermal systems development teams are consistently
							challenged with predicting and achieving a vehicle’s
							ideal ambient start-up performance, especially for cold
							climate operation.
						</p>
						<p>Difficulties include:</p>
						<ul>
							<li>
								incomplete knowledge of engine heat rejection and
								friction
							</li>
							<li>frequent calibration changes</li>
							<li>
								the significant costs associated with
								climate-controlled testing
							</li>
						</ul>
						<p>
							<strong>Validation</strong>
						</p>
						<p>
							Computational 1D models are essential for quantifying
							the effect of key parameters for engineers to achieve
							target performance and efficiency. Using this method, an
							experimental validation test was carried out by CSEG
							using WAVE, Realis Simulation formerly Ricardo
							Software’s 1D simulation package, and Flowmaster, the 1D
							computational Fluid Dynamics (CFD) tool from Mentor
							Graphics. Initially, using WAVE, a thermodynamic engine
							model was created and validated for a 2 liter,
							4-cylinder gasoline turbocharged direct injection engine
							with cooled Exhaust Gas Recirculation (EGR.) This model
							was used to simulate and predict engine heat rejection
							and quantify the effect of defined calibration
							parameters such as spark timing, air-fuel ratio,
							manifold pressure, and cylinder wall temperature in
							ambient temperature as low as -20 degrees Celsius/ -4
							degrees Fahrenheit.
						</p>
						<p>
							The defined calibration parameters were then applied to
							a cooling system, developed in Flowmaster where the
							effects of the engine and coolant thermal inertia and
							component performance degradation were taken into
							account to quantity the final effect of various key
							parameters on cabin air temperature. The combined
							WAVE-Flowmaster 1D model provided the following analysis
							and displayed the effects on HVAC performance:
						</p>
						<ul>
							<li>
								<strong>Spark timing</strong> – Time required to
								reach 50 degrees C was reduced by 110 seconds (23%)
								for 15-degree crank angle retarded spark timing and
								160 seconds (33%) for 25-degree crank angle spark
								timing. Similarly, coolant temperature increased by
								4 and 6 degrees C, respectively.
							</li>
							<li>
								<strong>Air-fuel ratio</strong> – Coolant warm-up
								was impacted adversely with a richer air-fuel ratio.
								With richer air flow, the time taken by coolant to
								reach 50 degrees C was increased by 220 seconds
								(150%) for AFR-3 and 900 seconds (290%) for AFR-5,
								when compared to stoichiometric AFR. Similarly,
								coolant temperature at the end of 10 minutes was
								reduced by 10 and 20 degrees C, respectively.
							</li>
							<li>
								<strong>Engine speed </strong>– Impact of change in
								engine speed on coolant warm-up was evaluated by
								increasing engine speed from 1500 rpm to 1700 rpm.
								Increased engine speed improved coolant warm-up and
								the time taken for the coolant to reach 50 degrees C
								was reduced by 100 seconds (21%) with 1700 rpm.
							</li>
						</ul>
						<p>
							<strong>Opportunity</strong>
						</p>
						<p>
							Using 1D computational models (engine and cooling), CSEG
							engineers were able to predict engine heat rejection for
							cold conditions and the effects of engine calibration.
							This fully computational predictive approach is vital in
							understanding the complex interactions and mechanics of
							engine operation at cold ambient warm-up and the levels
							that HVAC engineers need in order to meet targets in the
							early stages of design.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{WAVE_APPLICATIONS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductWavePage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-wave-cs1", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/products/wave/Realis-Simulation_WAVE.png" }) {
		...imageBreaker
	}
}
`;

